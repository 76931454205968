define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-info-members", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat/routes/channel-info-members", "discourse/plugins/chat/discourse/components/chat/routes/channel-info-nav", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _didInsert, _service, _discourseI18n, _navbar, _channelInfoMembers, _channelInfoNav, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesMembers extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    get backButton() {
      return {
        route: "chat.channel",
        models: this.chat.activeChannel?.routeModels,
        title: (0, _discourseI18n.i18n)("chat.return_to_channel")
      };
    }
    async fetchChannel() {
      if (!this.args.params?.channelId) {
        return;
      }
      const channel = await this.chatChannelsManager.find(this.args.params.channelId);
      this.chat.activeChannel = channel;
    }
    static #_4 = (() => dt7948.n(this.prototype, "fetchChannel", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="c-drawer-routes --channel-info-members"
          {{didInsert this.fetchChannel}}
        >
          {{#if this.chat.activeChannel}}
            <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
              <navbar.BackButton
                @title={{this.backButton.title}}
                @route={{this.backButton.route}}
                @routeModels={{this.backButton.models}}
              />
              <navbar.ChannelTitle @channel={{this.chat.activeChannel}} />
              <navbar.Actions as |a|>
                <a.ToggleDrawerButton />
                <a.FullPageButton />
                <a.CloseDrawerButton />
              </navbar.Actions>
            </Navbar>
    
            {{#if this.chatStateManager.isDrawerExpanded}}
              <div class="chat-drawer-content">
                <ChannelInfoNav
                  @channel={{this.chat.activeChannel}}
                  @tab="members"
                />
                <ChannelMembers @channel={{this.chat.activeChannel}} />
              </div>
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "+LTqGpTO",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"c-drawer-routes --channel-info-members\"],[4,[32,0],[[30,0,[\"fetchChannel\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\"]],[[[1,\"        \"],[8,[32,1],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,1,[\"BackButton\"]],null,[[\"@title\",\"@route\",\"@routeModels\"],[[30,0,[\"backButton\",\"title\"]],[30,0,[\"backButton\",\"route\"]],[30,0,[\"backButton\",\"models\"]]]],null],[1,\"\\n          \"],[8,[30,1,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,0,[\"chat\",\"activeChannel\"]]]],null],[1,\"\\n          \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n            \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n            \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n          \"]],[2]]]]],[1,\"\\n        \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-drawer-content\"],[12],[1,\"\\n            \"],[8,[32,2],null,[[\"@channel\",\"@tab\"],[[30,0,[\"chat\",\"activeChannel\"]],\"members\"]],null],[1,\"\\n            \"],[8,[32,3],null,[[\"@channel\"],[[30,0,[\"chat\",\"activeChannel\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"a\"],false,[\"if\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-info-members.js",
      "scope": () => [_didInsert.default, _navbar.default, _channelInfoNav.default, _channelInfoMembers.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDrawerRoutesMembers;
});