define("discourse/plugins/chat/discourse/components/chat-channel-unread-indicator", ["exports", "@glimmer/component", "discourse/helpers/concat-class", "@ember/component", "@ember/template-factory"], function (_exports, _component, _concatClass, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_UNREAD_COUNT = 99;
  class ChatChannelUnreadIndicator extends _component.default {
    get showUnreadIndicator() {
      return this.args.channel.tracking.unreadCount + this.args.channel.tracking.mentionCount + this.args.channel.unreadThreadsCountSinceLastViewed > 0;
    }
    get publicUrgentCount() {
      return this.args.channel.tracking.mentionCount + this.args.channel.tracking.watchedThreadsUnreadCount;
    }
    get directUrgentCount() {
      return this.args.channel.tracking.unreadCount + this.args.channel.tracking.mentionCount + this.args.channel.tracking.watchedThreadsUnreadCount;
    }
    get urgentCount() {
      return this.args.channel.isDirectMessageChannel ? this.directUrgentCount : this.publicUrgentCount;
    }
    get isUrgent() {
      return this.urgentCount > 0;
    }
    get urgentBadgeCount() {
      let totalCount = this.urgentCount;
      return totalCount > MAX_UNREAD_COUNT ? `${MAX_UNREAD_COUNT}+` : totalCount;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showUnreadIndicator}}
          <div
            class={{concatClass
              "chat-channel-unread-indicator"
              (if this.isUrgent "-urgent")
            }}
          >
            <div class="chat-channel-unread-indicator__number">
              {{#if this.isUrgent}}{{this.urgentBadgeCount}}{{else}}&nbsp;{{/if}}
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "sB67jwd9",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showUnreadIndicator\"]],[[[1,\"      \"],[10,0],[15,0,[28,[32,0],[\"chat-channel-unread-indicator\",[52,[30,0,[\"isUrgent\"]],\"-urgent\"]],null]],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-channel-unread-indicator__number\"],[12],[1,\"\\n          \"],[41,[30,0,[\"isUrgent\"]],[[[1,[30,0,[\"urgentBadgeCount\"]]]],[]],[[[1,\" \"]],[]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-unread-indicator.js",
      "scope": () => [_concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatChannelUnreadIndicator;
});