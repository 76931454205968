define("discourse/plugins/chat/discourse/components/chat-message-in-reply-to-indicator", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "@ember/template", "discourse/helpers/replace-emoji", "discourse-common/helpers/d-icon", "discourse/plugins/chat/discourse/components/chat-emoji-avatar", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _template, _replaceEmoji, _dIcon, _chatEmojiAvatar, _chatUserAvatar, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessageInReplyToIndicator extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get route() {
      if (this.hasThread) {
        return "chat.channel.thread";
      } else {
        return "chat.channel.near-message";
      }
    }
    get model() {
      if (this.hasThread) {
        return [...this.args.message.channel.routeModels, this.args.message.thread.id];
      } else {
        return [...this.args.message.channel.routeModels, this.args.message.inReplyTo.id];
      }
    }
    get hasThread() {
      return this.args.message?.channel?.threadingEnabled && this.args.message?.thread?.id;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @message.inReplyTo}}
          <LinkTo
            @route={{this.route}}
            @models={{this.model}}
            class="chat-reply is-direct-reply"
          >
            {{dIcon "share" title="chat.in_reply_to"}}
    
            {{#if @message.inReplyTo.chatWebhookEvent.emoji}}
              <ChatEmojiAvatar
                @emoji={{@message.inReplyTo.chatWebhookEvent.emoji}}
              />
            {{else}}
              <ChatUserAvatar @user={{@message.inReplyTo.user}} />
            {{/if}}
    
            <span class="chat-reply__excerpt">
              {{replaceEmoji (htmlSafe @message.inReplyTo.excerpt)}}
            </span>
          </LinkTo>
        {{/if}}
      
    */
    {
      "id": "524VGliK",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"inReplyTo\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"chat-reply is-direct-reply\"]],[[\"@route\",\"@models\"],[[30,0,[\"route\"]],[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"share\"],[[\"title\"],[\"chat.in_reply_to\"]]]],[1,\"\\n\\n\"],[41,[30,1,[\"inReplyTo\",\"chatWebhookEvent\",\"emoji\"]],[[[1,\"          \"],[8,[32,2],null,[[\"@emoji\"],[[30,1,[\"inReplyTo\",\"chatWebhookEvent\",\"emoji\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,3],null,[[\"@user\"],[[30,1,[\"inReplyTo\",\"user\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n        \"],[10,1],[14,0,\"chat-reply__excerpt\"],[12],[1,\"\\n          \"],[1,[28,[32,4],[[28,[32,5],[[30,1,[\"inReplyTo\",\"excerpt\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@message\"],false,[\"if\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-in-reply-to-indicator.js",
      "scope": () => [_routing.LinkTo, _dIcon.default, _chatEmojiAvatar.default, _chatUserAvatar.default, _replaceEmoji.default, _template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatMessageInReplyToIndicator;
});